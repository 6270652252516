@import 'src/assets/scss/_shared/variables';

%border-danger {
	@media (prefers-color-scheme: light) {
		border: 1px solid $form-color-error;
	}
	@media (prefers-color-scheme: dark) {
		border: 1px solid $form-color-error-light;
	}
}

%color-danger {
	@media (prefers-color-scheme: light) {
		color: $form-color-error;
	}
	@media (prefers-color-scheme: dark) {
		color: $form-color-error-light;
	}
}

form {
	app-form-field {
		ion-radio-group {
			padding-bottom: 20px;
			display: block;
			margin-left: -8px;

			ion-row {
				ion-col {
					ion-item {
						--padding-start: 0;
						--ion-safe-area-left: 0;
						--padding-end: 0;
						--min-height: 0;

						ion-radio {
						}

						ion-label {
							color: $form-font-color !important;
							font-size: $form-font-size !important;
							font-weight: 100 !important;
							margin: 0 0 0 10px !important;
						}
					}
				}
			}
		}
	}

	ion-col {
		padding: 0 8px;
		text-align: left;
		--highlight-background: transparent;

		&.ion-invalid {
			--highlight-background: transparent;
		}

		.item-native {
		}

		.item-highlight {
			display: none;
			visibility: hidden;
		}

		ion-label {
			font-size: 13px;
			font-weight: 600;

			@media (prefers-color-scheme: light) {
				color: #1d1d1d;
			}
		}

		ion-input,
		ion-select {
			font-size: $form-font-size !important;
			--padding-start: 12px;
			border-radius: 4px;
			margin-top: 6px;
			margin-bottom: 25px;

			@media (prefers-color-scheme: light) {
				color: $form-font-color !important;
				border: 1px solid $form-color-border !important;
				background-color: rgba(255, 255, 255, 0.9) !important;
			}

			@media (prefers-color-scheme: dark) {
				border: 1px solid $form-font-color !important;
			}

			&:focus {
				border: 1px solid $mtg-color-primary;
			}
		}

		ion-input {
			--padding-top: 10px !important;
			--padding-end: 8px !important;
			--padding-bottom: 10px !important;
			--padding-start: 8px !important;
		}

		ion-select {
			--padding-end: 5px;

			ion-radio-group {
				ion-item {
					color: $form-font-color !important;
					font-size: $form-font-size !important;
				}
			}
		}

		.form-field-container-error {
			ion-select {
				@extend %border-danger;
			}

			ion-label {
				@extend %color-danger;
			}

			ion-radio-group {
				ion-row {
					ion-col {
						ion-item {
							ion-radio {
								@extend %color-danger;
							}
						}
					}
				}
			}

			ion-radio-group {
				ion-item {
					ion-radio {
						@extend %color-danger;
					}
				}
			}

			ion-input {
				@extend %border-danger;
			}

			ion-checkbox {
				@media (prefers-color-scheme: light) {
					border-color: $form-color-error !important;
				}
				@media (prefers-color-scheme: dark) {
					border-color: $form-color-error-light !important;
				}

				& + .message {
					padding-top: 21px;
					margin-left: -3px;
					display: none;
				}
			}

			.message {
				&--error {
					@extend %color-danger;
				}
			}
		}

		.form-field-container-disabled {
			opacity: 0.4;
		}

		.message {
			height: 28px;
			display: block;
			position: absolute;
			bottom: -7px;
			left: 12px;
			margin-top: 5px !important;
			font-family: 'Lato', sans-serif !important;
			font-style: normal;
			font-weight: normal;
			font-size: 11px;
			line-height: 11px;
			align-items: center;
		}
	}

	ion-button {
		&.btn-block {
			display: block;
		}

		margin-left: 3px;
		margin-right: 3px;
		margin-top: 10px;
	}

	ion-item {
		--inner-padding-start: 0;
		--inner-padding-end: 0;
		--padding-start: 0;
		--padding-end: 0;

		ion-button {
			min-width: 100px;
		}
	}
}

ion-item {
	&.item-has-focus {
		--highlight-background: transparent;
	}
}
