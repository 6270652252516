ion-header {
	&::after {
		background-image: none !important;
	}

	ion-title {
		font-family: noto-sans;
		font-size: 17px;
		text-transform: capitalize;

		img {
			width: 100px;
			margin: 6px 0 3px;
		}
	}

	ion-badge {
		width: 22px;
		height: 22px;
		font-size: 10px;
		line-height: 18px;
		border-radius: 50%;
		top: -7px;
		position: relative;
		--background: #f0682a;
	}

	ion-buttons {
		font-size: 22px;
	}

	.btn-right {
		margin-right: 15px;

		img {
			width: 20px;
		}
	}
}
