ion-button {
	width: auto;
	box-shadow: inherit !important;
}

.button-solid {
	background: transparent;
}

.button:focus {
	outline: none !important;
}

.btn-row,
.btn-row-sm {
	text-align: center;
	margin-top: 26px;
	white-space: unset;
	font-weight: 500;
	height: 2.8rem;
}

.btn-row-sm {
	margin-top: 25px;
}

.btn-block {
	display: block;
}

.btn-orange-color {
	--background: #f0682a;
	&[fill='outline'] {
		--border-color: #f0682a;
		--color: #f0682a;
		--background: transparent;
	}
}

.btn-orange {
	font-family: noto-sans;
	text-transform: capitalize;
	font-size: 0.9rem;
	height: 2.7rem;
	--background: #f0682a;
	--padding-start: 1em;
	--padding-end: 1em;

	&[fill='outline'] {
		--border-color: #f0682a;
		--color: #f0682a;
		--background: transparent;
	}
}

.btn-link {
	font-size: 14px;
	--ion-color-contrast: #3880ff !important;
	white-space: unset;
	font-weight: 500;
	text-transform: none;

	&--unset {
		all: unset;
		background-color: transparent;
		color: #3880ff;
		//text-decoration: underline;
		cursor: pointer;
	}
}
