.infinite-scroll-flex {
	margin-top: 10px;

	.infinite-loading {
		display: flex !important;
		justify-content: center;
		flex-direction: row-reverse;

		.infinite-loading-spinner {
		}

		.infinite-loading-text {
			margin-inline-end: 7px;
			margin-top: 1px;
		}
	}
}
