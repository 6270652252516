$mtg-color-primary: #4f357f;
$mtg-color-primary-light: #7136e2;
$mtg-color-primary-light-ultra: #a779ff;

$form-font-color: #555;
$form-font-color-light: #7b7b7b;
$form-font-color-light-ultra: #b4b4b4;
$form-font-size: 14px;
$form-color-error: #a9221f;
$form-color-error-light: #ed576b;

$form-color-border: #a2a2a2;
