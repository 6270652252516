ion-content {
	&.ion-content-grid::part(scroll) {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: 0fr auto 80px;
		grid-gap: 1rem;
		height: 100%;
		align-content: stretch;
	}

	@media (prefers-color-scheme: light) {
		--background: #faf9f9;
	}
}
