ion-card {
	&.ion-card-alert {
		text-align: center;

		&--info {
			background: linear-gradient(88.12deg, #cfe2ff 1.53%, #cfe8ff 100%);
			color: #055160;
		}

		ion-card-content {
		}
	}

	> ion-card-content {
		form {
			width: 100%;
		}
	}
}
