.ion-chip-card-fake {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 90px;
	border-radius: 7px;
	font-size: 17px;
	color: #787887;
	margin-bottom: 26px;

	@media (prefers-color-scheme: light) {
		background-color: #f5f5fa;
	}
	@media (prefers-color-scheme: dark) {
		background-color: #121212;
	}

	&--center {
		align-items: center;
	}
}

.title {
	font-size: 25px;
}
