@import 'src/assets/scss/_shared/variables';

h1,
h2 {
	font-size: 20px;
	margin: 16px 10px 12px;
}

h5 {
	font-size: 16px;
	margin: 16px 10px 10px;
}

h5 {
	+ p {
		margin: 0 10px;
		font-size: 12px;

		@media (prefers-color-scheme: light) {
			color: $form-font-color;
		}
		@media (prefers-color-scheme: dark) {
			color: $form-font-color-light-ultra;
		}
	}
}
