/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@font-face {
	font-family: noto-sans;
	src: url('assets/fonts/noto-sans/NotoSans_gdi.woff') format('woff'), url('assets/fonts/noto-sans/NotoSans_gdi.svg#icon') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: noto-sans;
	src: url('assets/fonts/noto-sans/NotoSans_Bold.woff') format('woff'), url('assets/fonts/noto-sans/NotoSans_Bold.svg#icon') format('svg');
	font-weight: 700;
	font-style: normal;
}

:root {
	--ion-color-primary: #488aff;
	--ion-color-secondary: #32db64;
	--ion-color-danger: #f53d3d;
	--ion-color-light: #f4f4f4;
	--ion-color-dark: #222;
}

@import './assets/scss/index';

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
ion-buttons,
ion-toast,
ion-label,
ion-input,
ion-textarea,
ion-item,
small,
.select-text,
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios,
ul li {
	font-family: noto-sans, serif !important;
}

p {
	font-size: 14px;
	line-height: 21px;

	@media (prefers-color-scheme: light) {
		color: #212121;
	}
}

input:focus {
	outline: none !important;
}

.btn-transparent {
	--background: transparent !important;
	--color: #000 !important;
	--box-shadow: none;
	margin-top: 9px !important;
	font-size: 1rem !important;
}

.searchbar-input.sc-ion-searchbar-md {
	border: 1px solid #e2e2e2;
	border-radius: 30px;
	font-family: 'Lato', sans-serif !important;
	height: 48px;
	box-shadow: inset 0 2px 10px -1px rgba(0, 0, 0, 0.25882352941176473) !important;
}

.searchbar-input.sc-ion-searchbar-ios {
	border: 1px solid #e2e2e2;
	border-radius: 30px;
	font-family: 'Lato', sans-serif !important;
	height: 48px;
	box-shadow: inset 0 2px 10px -1px rgba(0, 0, 0, 0.25882352941176473) !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
	left: 16px;
	top: 14px;
	width: 21px;
	height: 21px;
	color: #8d8e90;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
	left: 16px;
	top: 14px;
	width: 21px;
	height: 21px;
	color: #8d8e90;
}

ion-backdrop {
	opacity: 0.5 !important;
}

.action-sheet-title {
	font-weight: 600 !important;
	color: #fff !important;
	padding: 18px 16px !important;
	height: 52px !important;
	font-size: 16px !important;
	background: #f0682a !important;
}

.action-sheet-button {
	height: 44px !important;
	font-size: 15px !important;
	color: #000 !important;
	font-family: noto-sans !important;
}

.alert-title.sc-ion-alert-md,
.alert-title.sc-ion-alert-ios {
	font-size: 18px;
	font-weight: 600;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 10px 10px 0;
}

.headline-area {
	padding-bottom: 30px;

	h3 {
		font-size: 31px;
		line-height: 40px;
		font-weight: 600;
		color: #444;
	}
}

hr {
	padding: 0 !important;

	@media (prefers-color-scheme: light) {
		border-bottom: 1px solid #dedede;
	}
	@media (prefers-color-scheme: light) {
		border-bottom: 1px solid #373737;
	}
}

::shadow .item-native {
	--background: red !important;
	--border-color: red;
	transition: var(--transition);
	border-width: var(--border-width);
	border-style: var(--border-style);
	border-color: red;
	outline: none;
	background: red !important;
	overflow: inherit;
	box-sizing: border-box;
}
